import image1 from "../../img/home-5.png";
import image2 from "../../img/Home7.png";
import image3 from "../../img/home-6.png";
import image4 from "../../img/home-10.png";

const HOMEPAGE_DATA = {
    interiorSections: [
        {
            title: "About us",
            bodyCopy: "Urology Specialists of Nevada (USONV) was founded in 1996 by Dr. Robert McBeath, a lifelong Nevada resident and second-generation physician. His goal was to create the highest-quality and most technologically advanced urology practice in the Las Vegas valley. Today, USONV is one of the largest urology practices in Southern Nevada.",
            imageUrl: image1,
            id: 1
        },
        {
            button: true,
            linklabel: 'Find a provider',
            linkUrl: 'providers',
            bodyCopy: "We want to meet your care needs as quickly as possible. That’s why you have advanced practice clinicians (APCs) on your care team. You may see a nurse practitioner (NP) or physician assistant (PA) at your visit. They work closely with your urologist. Whether you see your urologist or an APC, they share all the information about your care. You’ll have everyone on the same page looking out for your health.",
            imageUrl: image2,
            imageLeft: true,
            id: 2
        },
        {
            bodyCopy: `We're dedicated to offering the best care for urologic issues. USONV offers the most advanced surgical procedures and technology. We also use the latest in diagnostic and care choices.`,
            imageUrl: image3,
            id: 3
        },
        // {
        //     htmlCopy: `In 2012, radiation oncologist Dr. Michael Becker joined to form Nevada Cancer Specialists. Urology and oncology are complementary specialties. With Dr. Becker’s leadership, we are able to deliver unparalleled continuity of care to our patients who require both.`,
        //     imageUrl: image4,
        //     imageLeft: true,
        //     id: 4
        // }
    ]
}

export default HOMEPAGE_DATA;
